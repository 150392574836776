import type { IosProps } from '../types'

export function iosEvent<T>({
	command,
	listener,
	optionalParams,
}: IosProps<T>) {
	if (!window.webkit?.messageHandlers?.[listener]) {
		throw new Error(`iOs listener: ${listener}, not found`)
	}

	const message = {
		command,
		...(optionalParams ?? {}),
	}

	return window.webkit.messageHandlers[listener].postMessage(message)
}
