import type { BeaconAddToCartEvent } from '../events/eec/addToCart/addToCart'
import type { BeaconAddToWishlistEvent } from '../events/eec/addToWishlist/addToWishlist'
import type { BeaconBeginCheckoutEvent } from '../events/eec/beginCheckout/beginCheckout'
import type { BeaconRemoveFromCartEvent } from '../events/eec/removeFromCart/removeFromCart'
import type { BeaconRemoveFromWishlistEvent } from '../events/eec/removeFromWishlist/removeFromWishlist'
import type { BeaconSelectItemEvent } from '../events/eec/selectItem/selectItem'
import type { BeaconViewCartEvent } from '../events/eec/viewCart/viewCart'
import type { BeaconViewItemEvent } from '../events/eec/viewItem/viewItem'
import type { BeaconViewItemListEvent } from '../events/eec/viewItemList/viewItemList'
import type { Item } from '../utils/getEECItems/getEECItems'
import type { CommonParams } from './CommonParams'

export enum EECEventType {
	ADD_PAYMENT_INFO = 'add_payment_info',
	ADD_SHIPPING_INFO = 'add_shipping_info',
	ADD_TO_CART = 'add_to_cart',
	ADD_TO_WISHLIST = 'add_to_wishlist',
	REMOVE_FROM_WISHLIST = 'remove_from_wishlist',
	BEGIN_CHECKOUT = 'begin_checkout',
	PURCHASE = 'purchase',
	REMOVE_FROM_CART = 'remove_from_cart',
	SELECT_ITEM = 'select_item',
	SELECT_PROMOTION = 'select_promotion',
	VIEW_CART = 'view_cart',
	VIEW_ITEM = 'view_item',
	VIEW_ITEM_LIST = 'view_item_list',
	VIEW_PROMOTION = 'view_promotion',
}

export interface Product {
	quantity: number
	item_id: string
}

export type ProductPayload = {
	products: [Product]
	itemListId: string
}

export type AddShippingInfoPayload = {
	shipping_tier: string
	items: Product[]
	coupon?: string
}

export type AddPaymentInfoPayload = {
	payment_type: string
	items: Product[]
	coupon?: string
}

export type PurchasePayload = {
	transaction_id: string
	shipping: number
	tax: number
	items: Product[]
	coupon?: string
}

export interface Promotion {
	promotion_id: string
	promotion_name: string
	creative_name: string
	creative_slot: string
	is_filtered_by?: string
}

export interface PromotionPayload {
	promotions: Promotion[]
}

export interface ViewItemPayload {
	item: Item
}

export type EECEventPayload =
	| PromotionPayload
	| ProductPayload
	| AddShippingInfoPayload
	| AddPaymentInfoPayload

export type PromotionsEvent =
	| {
			items: Promotion[]
	  }
	| CommonParams

export type PurchaseEvent = PurchasePayload | CommonParams

export type AddShippingInfoEvent = AddShippingInfoPayload | CommonParams

export type AddPaymentInfoEvent = AddPaymentInfoPayload | CommonParams

export interface BeaconPurchaseEvent {
	name: EECEventType.PURCHASE
	params: PurchaseEvent
}

export interface BeaconSelectPromotionsEvent {
	name: EECEventType.SELECT_PROMOTION
	params: PromotionsEvent
}

export interface BeaconViewPromotionsEvent {
	name: EECEventType.VIEW_PROMOTION
	params: PromotionsEvent
}

export interface BeaconAddShippingInfoEvent {
	name: EECEventType.ADD_SHIPPING_INFO
	params: AddShippingInfoEvent
}

export interface BeaconAddPaymentInfoEvent {
	name: EECEventType.ADD_PAYMENT_INFO
	params: AddPaymentInfoEvent
}

export interface MngItem {
	item_id: string
	item_category2: string
	item_variant: string
	quantity: number
}

export type BeaconEvent =
	| BeaconViewPromotionsEvent
	| BeaconSelectPromotionsEvent
	| BeaconAddToCartEvent
	| BeaconPurchaseEvent
	| BeaconAddShippingInfoEvent
	| BeaconAddPaymentInfoEvent
	| BeaconViewItemEvent
	| BeaconViewCartEvent
	| BeaconRemoveFromCartEvent
	| BeaconBeginCheckoutEvent
	| BeaconSelectItemEvent
	| BeaconViewItemListEvent
	| BeaconAddToWishlistEvent
	| BeaconRemoveFromWishlistEvent
