'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import {
	defaultCountry,
	defaultLanguage,
	regexHaveCountryAndLanguage,
} from 'country-configuration/constants'
import { useClientLabels } from 'labels/hooks/useClientLabels/useClientLabels'
import { errorKeys } from 'monitoring/keys'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

// Can't use css, nextjs bug on global-error.tsx
// https://github.com/vercel/next.js/issues/46964
import { styles } from './ErrorComponent.styles'

// Can't use useState, reset() erase everything
let retries = 1

const MAX_RETRIES = 3

export const ErrorComponent = ({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) => {
	let countryISO
	let languageISO
	const { pathname } = window.location
	if (regexHaveCountryAndLanguage.test(pathname)) {
		;[countryISO, languageISO] = pathname.split('/').slice(1)
	} else {
		countryISO = defaultCountry
		languageISO = defaultLanguage
	}

	const router = useRouter()
	const { t } = useClientLabels({
		keys: errorKeys,
		countryISO,
		languageISO,
	})

	useEffect(() => {
		if (error && retries === 1) {
			sendGenericEvent('error', {
				error,
			})
		}
	}, [error])

	const handleReset = () => {
		retries = retries + 1
		reset()
	}

	const handleDiscover = () => {
		retries = 1
		router.push('/')
	}

	return (
		<div style={styles.container}>
			{retries > MAX_RETRIES ? (
				<>
					<span style={styles.title}>{t('error.notAvailable.title')}</span>
					<span>{t('error.notAvailable.bodycopy')}</span>
					<button type='button' onClick={handleDiscover} style={styles.button}>
						{t('error.discover.button')}
					</button>
				</>
			) : (
				<>
					<span style={styles.title}>{t('error.display.title')}</span>
					<span>{t('error.display.bodycopy')}</span>
					<button type='button' onClick={handleReset} style={styles.button}>
						{t('error.retry.button')}
					</button>
				</>
			)}
		</div>
	)
}
