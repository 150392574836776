import { CommonGenericEvents } from '../events/generics/commonGenericEvents'
import { CustomEventTypes } from '../types/CustomEvent'
import { EECEventType } from '../types/EEC'
import { GTMEventTypes } from '../types/GTM'

export enum RecommendedEventTypes {
	SEARCH = 'search',
	LOGIN = 'login',
	SIGNUP = 'signup',
}

export type GenericEvents = CommonGenericEvents

export const AnalyticsEvents = {
	...CustomEventTypes,
	...RecommendedEventTypes,
	...GTMEventTypes,
	...EECEventType,
	...CommonGenericEvents,
}

export type AnalyticsEventType =
	| CustomEventTypes
	| RecommendedEventTypes
	| GTMEventTypes
	| EECEventType
	| GenericEvents
