'use client'

import type { AnalyticsEventType } from 'analytics/constants/events'

import { isAnalyticsObject } from '../../types/DataLayerEvent'

export const isAlreadySent = (event: AnalyticsEventType): boolean =>
	window.dataLayer?.some(
		(dataLayerEvent) =>
			isAnalyticsObject(dataLayerEvent) && dataLayerEvent.event === event
	)
