import { CustomEventTypes } from './CustomEvent'
import type { DataLayerEvent } from './DataLayerEvent'

export enum GTMEventTypes {
	GTM_JS = 'gtm.js',
	GTM_LOAD = 'gtm.load',
	GTM_DOM = 'gtm.dom',
	GTM_START = 'gtm.start',
}

interface GTMStart {
	event: GTMEventTypes.GTM_JS
	'gtm.start': number
}

interface GTMDom {
	event: GTMEventTypes.GTM_DOM
}

interface GTMLoad {
	event: GTMEventTypes.GTM_LOAD
}

export type GTMConsent = ['consent', 'default']

export type GTMPageview = ['event', CustomEventTypes.LOAD_DATALAYER]

export type GTMEvent = GTMStart | GTMLoad | GTMDom | GTMConsent | GTMPageview

export function isGTMConsent(event: DataLayerEvent): event is GTMConsent {
	return (
		(event as GTMConsent)[0] === 'consent' &&
		(event as GTMConsent)[1] === 'default'
	)
}

export function isGTMPageview(event: DataLayerEvent): event is GTMPageview {
	return (event as GTMPageview)[1] === CustomEventTypes.LOAD_DATALAYER
}
