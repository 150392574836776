import type { PageType } from 'types/analytics'
import type { CountryConfiguration } from 'types/countryConfiguration'
import type { Channel, Device, Environment } from 'types/masterData'
import type { CustomDomainData } from 'types/masterData/domainData/domainData'

import type { AnalyticsEventType } from '../constants/events'
import type { LoadDataLayerEvent, NullableEvent } from './CustomEvent'
import type { ExperimentEvent } from './Experiment'
import type { GTMEvent } from './GTM'
import type { GenericEvent } from './Generic'

export type DataLayerEvent = AnalyticsObject | GTMEvent | IArguments

export type DataLayerDataObject = WebDataLayerData | AppsDataLayerData

export type AnalyticsObject =
	| LoadDataLayerEvent
	| DataLayerDataObject
	| ExperimentEvent
	| GenericEvent
	| NullableEvent

interface DataLayerData {
	ads_consent: boolean
	analytics_consent: boolean
	application: Channel
	country_iso: CountryConfiguration['countryISO']
	email?: string
	environment: Environment
	external_id?: string
	error: string | null
	event: AnalyticsEventType
	has_online_sale: CountryConfiguration['hasOnlineSale']
	is_votf: boolean
	language_iso: CountryConfiguration['languageISO']
	page_brand: string | null
	page_type: PageType
	platform: Device
	user_logged: boolean
	genesis: true
	custom?: CustomDomainData
}

interface AppsDataLayerData extends DataLayerData {
	event_name?: null
	is_app: true
	is_votf: false
}

interface WebDataLayerData extends DataLayerData {
	event_name?: null
	is_app: false
	is_votf: boolean
}

export function isAnalyticsObject(
	event: DataLayerEvent
): event is AnalyticsObject {
	return (event as AnalyticsObject).hasOwnProperty('event')
}
