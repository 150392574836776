import { isIos } from '../constants'
import type { AndroidProps, IosProps } from '../types'
import { androidEvent } from './androidEvent'
import { iosEvent } from './iosEvent'

export function sendAppEvent<T>({
	command,
	listener,
	optionalParams,
}: IosProps<T> | AndroidProps<T>) {
	if (isIos()) {
		return iosEvent({ command, listener, optionalParams } as IosProps<T>)
	}

	return androidEvent({ command, optionalParams } as AndroidProps<T>)
}
