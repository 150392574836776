'use client'

import { useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'
import type { Labels, LabelsNamespaces } from 'types/labels'
import { env } from 'utils/envManager'

import type { LabelsService } from '../../types'

interface UseClientLabelsProps {
	countryISO: string
	languageISO: string
	keys?: string[]
	namespaces?: LabelsNamespaces[]
	suspense?: boolean
}

export function useClientLabels({
	keys,
	namespaces,
	countryISO,
	languageISO,
	suspense = true,
}: UseClientLabelsProps): {
	labels: Labels | undefined
	t: (key: string) => string
} {
	const doFetchKeys = keys && keys.length !== 0
	const doFetchNamespaces = namespaces && namespaces.length !== 0
	const url = `${env.NEXT_PUBLIC_SITE_BASE_URL}/ws-labels/labels/v2/`

	const { data: dataKeys } = useSWRImmutable<LabelsService | null>(
		[keys],
		() => {
			const urlKeys = new URL(`${url}${(keys ?? []).join(',')}`)
			urlKeys.searchParams.append('languageIso', languageISO)
			urlKeys.searchParams.append('countryIso', countryISO.toUpperCase())
			return doFetchKeys
				? fetch(urlKeys, {
						next: { revalidate: 0 },
					}).then((res) => res.json())
				: null
		},
		{ suspense }
	)

	const { data: dataNamespace } = useSWRImmutable<LabelsService[] | null>(
		[namespaces],
		() =>
			doFetchNamespaces
				? Promise.all(
						namespaces.map((namespace) => {
							const urlNamespace = new URL(`${url}/namespace/${namespace}`)
							urlNamespace.searchParams.append('languageIso', languageISO)
							urlNamespace.searchParams.append(
								'countryIso',
								countryISO.toUpperCase()
							)
							return fetch(urlNamespace, {
								next: { revalidate: 0 },
							}).then((res) => res.json())
						})
					)
				: null,
		{ suspense }
	)

	const data = useMemo(
		() => ({
			...dataNamespace
				?.map((d) => d.translations)
				.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
			...dataKeys?.translations,
		}),
		[dataKeys, dataNamespace]
	)

	return useMemo(
		() => ({
			labels: data,
			t: (key: string) => data?.[key] || key,
		}),
		[data]
	)
}
