'use client'

import type { AnalyticsObject } from 'analytics/types/DataLayerEvent'
import { isNativeApp } from 'webview/constants'
import { sendAppAnalytic } from 'webview/utils/sendAppAnalytic'

import { AnalyticsEvents } from '../../constants/events'
import { DATALAYER_EVENTS_QUEUE } from '../../events/generics/queue'
import { isAlreadySent } from '../isAlreadySent/isAlreadySent'

export const pushToDatalayer = (payload: AnalyticsObject): void => {
	if (isAlreadySent(AnalyticsEvents.DATALAYER_DATA)) {
		const eventName = payload.event_name
		if (isNativeApp() && eventName) {
			sendAppAnalytic(eventName, payload[eventName] as Record<string, unknown>)
		} else {
			if (eventName) {
				window.dataLayer.push({ [eventName]: null })
			}
			window.dataLayer.push(payload)
		}
	} else {
		DATALAYER_EVENTS_QUEUE.push(payload)
	}
}
