export const notFoundKeys = [
	'error404.title',
	'error404.bodycopy',
	'error.discover.button',
]

export const errorKeys = [
	'error.display.title',
	'error.display.bodycopy',
	'error.retry.button',
	'error.notAvailable.title',
	'error.notAvailable.bodycopy',
	'error.discover.button',
]

export const keys = [...notFoundKeys, ...errorKeys]
