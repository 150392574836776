export enum CustomEventTypes {
	LOAD_DATALAYER = 'loadDataLayer',
	SCREEN_VIEW = 'screen_view',
	EXPERIMENT = 'GA4-experiment',
	DATALAYER_DATA = 'dataLayerData',
	GENERIC = 'GA4-generic',
}

export interface LoadDataLayerEvent {
	event: CustomEventTypes.LOAD_DATALAYER
	event_name?: null
}

export interface NullableEvent {
	[key: string]: null
}
