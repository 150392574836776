import type { AnalyticsObject } from '../../types/DataLayerEvent'

export const DATALAYER_EVENTS_QUEUE: AnalyticsObject[] = []

export const sendQueuedEvents = (): void => {
	DATALAYER_EVENTS_QUEUE.forEach((analyticsObject) => {
		window.dataLayer.push(analyticsObject)
	})
	DATALAYER_EVENTS_QUEUE.length = 0
}
