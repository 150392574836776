import { type CSSProperties } from 'react'

interface Styles {
	container: CSSProperties
	title: CSSProperties
	button: CSSProperties
}

export const styles: Styles = {
	container: {
		height: '65dvh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
		margin: '1rem',
	},
	title: {
		fontWeight: '400',
		fontSize: '1.25rem',
		lineHeight: '1.88rem',
		fontFamily: 'var(--mango-primary-font)',
		letterSpacing: '0.3px',
		marginBottom: '1rem',
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '0.25rem',
		cursor: 'pointer',
		boxSizing: 'border-box',
		padding: '0 2rem',
		minWidth: '5.625rem',
		border: '0',
		height: '2.75rem',
		margin: '2rem',
		color: '#fff',
		backgroundColor: '#131313',
	},
}
