import type { AndroidProps } from '../types'

export function androidEvent<T>({ command, optionalParams }: AndroidProps<T>) {
	if (!window.NavigatorWebInterface?.[command]) {
		throw new Error(`Android listener function: ${command}, not found`)
	}

	if (optionalParams) {
		return window.NavigatorWebInterface[command](JSON.stringify(optionalParams))
	}

	return window.NavigatorWebInterface[command]()
}
