import { CustomEventTypes } from '../../types/CustomEvent'
import type { GenericEvent } from '../../types/Generic'
import { pushToDatalayer } from '../../utils/pushToDatalayer/pushToDatalayer'

export const sendGenericEvent = (
	eventName: string,
	eventData?: Record<string, unknown>
): void => {
	const payload: GenericEvent = {
		event: `${CustomEventTypes.GENERIC}-${eventName}`,
		event_name: eventName,
	}

	if (eventData) {
		payload[eventName] = eventData
	}

	pushToDatalayer(payload)
}
